<template>
  <div class="d-inline custom-dropdown">
    <b-dropdown size="sm" class="mx-1" right>
      <template #button-content>
        Action
      </template>

      <b-dropdown-item
        v-for="(action, index) in actionBtnText()"
        :key="index"
        @click="action.action(item)"
      >
        <span
          v-if="approveBusy"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{ action.text }}
      </b-dropdown-item>
    </b-dropdown>

    <b-modal
      id="approveAdmission.modal"
      ref="approveAdmission.modal"
      v-model="modal"
      centered
      hide-footer
      hide-header
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #default="{ hide }">
        <div>
          <div class="card custom-bg border-0">
            <div class="card-header border-0 p-2">
              <div class="w-100 text-center">
                <span class="text-monospace">Recommend For Admission</span>
                <b-icon
                  icon="x-circle"
                  scale="1"
                  variant="danger"
                  @click="hide()"
                  class="card-tools float-right"
                ></b-icon>
              </div>
            </div>
            <div class="card-body">
              <div class="m-1 p-2 bg-light shadow border border-info">
                <b class="p-0 m-0"
                  >Name: {{ item.surname }}
                  {{ item.firstname }}
                  {{ item.lastname }}</b
                >
                <br />
                <b class="p-0 m-0">Applied For: {{ item.applyFor }} </b>
              </div>
              <label
                >Select the programme you wish to recommend for this
                applicant</label
              >
              <v-select
                label="programme"
                :options="programmes"
                v-model="approvedProgramme"
                :class="{ 'is-invalid': errors.approvedProgramme }"
              ></v-select>
              <div v-if="errors.approvedProgramme" class="text-danger">
                {{ errors.approvedProgramme }}
              </div>
              <!-- <p>{{approvedProgramme}}</p> -->
              <!-- <v-select></v-select> -->
              <div class="app-card pl-1 border-left-decoration mt-3">
                <b class="mb-1">Programme applied for by student </b> <br />
                <label>{{ programme.programme }}</label>
              </div>
              <div class="mt-2">
                <button
                  class="btn btn-sm btn-outline-primary"
                  @click="recommend(item)"
                >
                  continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="denyAdmission.modal"
      ref="denyAdmission.modal"
      v-model="denyModal"
      centered
      hide-footer
      hide-header
      @shown="denyShown"
      @hidden="denyHidden"
    >
      <template #default="{ hide }">
        <div>
          <div class="card custom-bg border-0">
            <div class="card-header border-0 p-2">
              <div class="w-100 text-center">
                <span class="text-monospace text-danger">
                  Application Rejection</span
                >
                <b-icon
                  icon="x-circle"
                  scale="1"
                  variant="danger"
                  @click="hide()"
                  class="card-tools float-right"
                ></b-icon>
              </div>
            </div>
            <div class="card-body">
              <div class="m-1 p-2 bg-light shadow border border-info">
                <b class="p-0 m-0"
                  >Name: {{ item.surname }}
                  {{ item.firstname }}
                  {{ item.lastname }}</b
                >
                <br />
                <b class="p-0 m-0">Applied For: {{ item.applyFor }} </b>
              </div>
              <div class="app-card pl-1 border-left-decoration mt-3">
                <b class="mb-1">Programme applied for by student </b> <br />
                <label>{{ programme.programme }}</label>
              </div>

              <div class="form-group">
                <b class="mt-1"
                  >Reason for not recommending applicant for admission</b
                >
                <textarea
                  v-model="denyReason"
                  class="form-control form-control-lg small-font p-1"
                  :class="{ 'is-invalid': errors.denyReason }"
                  rows="5"
                  placeholder="State reason"
                >
                </textarea>
                <div v-if="errors.denyReason" class="text-danger">
                  {{ errors.denyReason }}
                </div>
              </div>

              <div class="mt-2">
                <button
                  class="btn btn-sm btn-outline-primary"
                  @click="UnRecommend(item)"
                >
                  Reject application
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <pending-application-modal />
  </div>
</template>

<script>
import selectSettingsModal from "../../helper/selectSettings.modal.vue";
import PendingApplicationModal from "./pending.application.modal.vue";
export default {
  components: { selectSettingsModal, PendingApplicationModal },
  props: ["index", "item"],
  data() {
    return {
      modal: false,
      denyModal: false,
      programme: "",
      approvedProgramme: "",
      approveBusy: false,
      denyBusy: false,
      denyReason: "",
      check: true,
      errors: {},
    };
  },
  created() {
    Fire.$off("FinishedSelectingSettings");
    Fire.$on("FinishedSelectingSettings", (data) => {
      this.$store.dispatch("spinner/showSpinner");
      this.approve(data.dataSent, data.settings);
    });
  },

  computed: {
    programmes() {
      return this.$store.state.programmes;
    },
  },

  methods: {
    onShown() {},
    onHidden() {},
    denyShown() {},
    denyHidden() {},
    getProgrammeForApprove() {
      return new Promise((res, rej) => {
        axios
          .post("admin/getProgrammeForApprove", { applicationId: this.item.id })
          .then((response) => {
            if (response.data.programme) {
              res(response.data);
              // this.programme = response.data.programme;
              // this.modal = true;
            } else {
              res(false);
              Swal.fire({
                title: "Application is not completed",
                icon: "warning",
              });
            }
            this.approveBusy = false;
          })
          .catch((err) => {
            rej(false);
            Swal.fire({
              title: "Application is not completed",
              icon: "warning",
            });
            this.approveBusy = false;
          });
      });
    },
    approveBtn(item) {
      if (item.status == "approved") {
        return true;
      }
      if (item.status == "submitted under processing") {
        return false;
      } else {
        return true;
      }
    },

    actionBtnText() {
      const { roleInUse } = this.$store.state.user;

      // Common actions for admin and pgSchool roles
      const adminActions = [
        {
          url: "",
          alertText: "You are about to approve an application for admission",
          text: "Approve",
          action: this.loadSelectSettingsModal,
        },
        {
          url: "",
          alertText: "You are about to deny an application for admission",
          text: "Deny",
          action: this.deny,
        },
        {
          url: "",
          alertText:
            "You are about to reset this application for the applicant to edit the form",
          text: "Reset for edit",
          action: this.reset_for_app_adit,
        },
        // {
        //   url: "",
        //   alertText: "You are about to reset this application's approval",
        //   text: "Reset Approval",
        //   action: this.reset_approval,
        // },
      ];

      // Common actions for other roles
      const userActions = [
        {
          url: "",
          alertText: "",
          text: "Recommend",
          action: this.handleRecom,
        },
        {
          url: "",
          alertText: "",
          text: "Not Recommended",
          action: this.handleUnRecommend,
        },
        {
          url: "",
          alertText: "",
          text: "Pend",
          action: this.handlePending,
        },
        {
          url: "",
          alertText: "",
          text: "Reset for edit",
          action: this.reset_for_app_adit,
        },
        {
          url: "",
          alertText: "",
          text: "Reset recommendation status",
          action: this.reset_recommendation_status,
        },
      ];

      // Return actions based on role
      return roleInUse === "admin" || roleInUse === "pgSchool"
        ? adminActions
        : userActions;
    },

    async handleApprove() {
      this.approveBusy = true;
      try {
        let approvedProgramme = await this.getProgrammeForApprove();
        if (approvedProgramme) {
          this.programme = approvedProgramme.programme;
          this.modal = true;
        }
        this.approveBusy = false;
      } catch (error) {
        console.log("error");
        // Swal.fire({
        //   title: "Application is not completed",
        //   icon: "warning",
        // });
        this.approveBusy = false;
      }
    },
    loadSelectSettingsModal(item) {
      console.log("load settings modal", item.id);
      Fire.$emit("SelectedSettings", item);
    },
    getSelectedSettings() {
      return new Promise((res, rej) => {});
    },
    async approve(item, settings) {
      console.log(item);
      let approvedProgramme = await this.getProgrammeForApprove();
      this.programme = approvedProgramme.programme;
      this.$store.dispatch("spinner/hideSpinner");
      Swal.fire({
        title: "You are about to approve an application for admission",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "YES",
        cancelButtonText: "NO",
      }).then((result) => {
        if (result.isConfirmed) {
          let details = {
            applicant: item.applicantId,
            applicationId: item.id,
            programmeId: item.approved_programme_id,
            admsStatus: "approved",
          };
          this.$store
            .dispatch("AdmissionOfficer/approveAdmission", {
              ...details,
              ...settings,
            })
            .then((res) => {
              // console.log(response);
              Fire.$emit("acceptAdmission");
              Toast.fire({
                icon: "info",
                title: res.data.message,
              });
              this.$store.dispatch("spinner/hideSpinner");
            })
            .catch((err) => {
              Toast.fire({
                icon: "error",
                title: err.response.data.message,
              });
            });
        } else {
          Toast.fire({
            icon: "info",
            title: "Operation Cancelled",
          });
          //   throw new axios.Cancel('Operation canceled by the user.');
        }
      });
    },
    async handleDeny(item) {
      this.denyBusy = true;
      try {
        let approvedProgramme = await this.getProgrammeForApprove();
        if (approvedProgramme) {
          this.programme = approvedProgramme.programme;
          this.denyModal = true;
        }
        this.denyBusy = false;
      } catch (error) {
        console.log(error);
        this.denyBusy = false;
      }
    },
    deny(item) {
      console.log(item);
      Swal.fire({
        title: "You are about to deny an application for admission",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "YES",
        cancelButtonText: "NO",
      }).then((result) => {
        if (result.isConfirmed) {
          let details = {
            applicant: item.applicantId,
            applicationId: item.id,
            admsStatus: "denied",
            programmeId: item.programme_id,
            // denyReason:this.denyReason
          };
          this.$store
            .dispatch("AdmissionOfficer/denyAdmission", details)
            .then((response) => {
              // console.log(response);
              Fire.$emit("acceptAdmission");
              Toast.fire({
                icon: "info",
                title: "Done",
              });
            })
            .catch((err) => {
              console.log(err);
              Toast.fire({
                icon: "error",
                title: "An error occured try again",
              });
            });
        } else {
          Toast.fire({
            icon: "info",
            title: "Operation Cancelled",
          });
          //   throw new axios.Cancel('Operation canceled by the user.');
        }
      });
    },

    async reset_for_app_adit(item) {
      const swalOptions = {
        title:
          "You are about to reset application to be editable for the applicant!",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "YES",
        cancelButtonText: "NO",
      };

      Swal.fire(swalOptions).then((result) => {
        if (result.isConfirmed) {
          const details = { application_id: item.id };
          this.$store
            .dispatch("AdmissionOfficer/reset_for_app_adit", details)
            .then((res) => {
              Fire.$emit("acceptAdmission");
              this.showToast("info", res.data.message);
              this.$store.dispatch("spinner/hideSpinner");
            })
            .catch((err) => {
              this.showToast("info", "Failed to reset application");
            });
        } else {
          this.showToast("info", "Operation Cancelled");
        }
      });
    },

    reset_approval(item) {
      const swalOptions = {
        title:
          "You are about to reset admission approval for this application!",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "YES",
        cancelButtonText: "NO",
      };

      Swal.fire(swalOptions).then((result) => {
        if (result.isConfirmed) {
          const details = { application_id: item.id };
          this.$store
            .dispatch(
              "AdmissionOfficer/reset_application_for_pg_actions",
              details
            )
            .then((res) => {
              Fire.$emit("acceptAdmission");
              this.showToast("info", res.data.message);
              this.$store.dispatch("spinner/hideSpinner");
            })
            .catch((err) => {
              this.showToast("info", "Failed to reset application");
            });
        } else {
          this.showToast("info", "Operation Cancelled");
        }
      });
    },

    reset_recommendation_status(item) {
      const swalOptions = {
        title: "You are about to reset the recommendation actions",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "YES",
        cancelButtonText: "NO",
      };

      Swal.fire(swalOptions).then((result) => {
        if (result.isConfirmed) {
          const details = { application_id: item.id };
          this.$store
            .dispatch("AdmissionOfficer/reset_recommendation_status", details)
            .then((res) => {
              Fire.$emit("acceptAdmission");
              this.showToast("info", res.data.message);
              this.$store.dispatch("spinner/hideSpinner");
            })
            .catch((err) => {
              Toast.fire({
                icon: "error",
                title: err.response.data.message,
              });
            });
        } else {
          this.showToast("info", "Operation Cancelled");
        }
      });
    },
    async handleRecom() {
      this.$store.dispatch("spinner/showSpinner");
      try {
        let approvedProgramme = await this.getProgrammeForApprove();
        if (approvedProgramme) {
          this.programme = approvedProgramme.programme;
          this.modal = true;
        }
        this.$store.dispatch("spinner/hideSpinner");
      } catch (error) {
        console.log("error");
        // Swal.fire({
        //   title: "Application is not completed",
        //   icon: "warning",
        // });
        this.$store.dispatch("spinner/hideSpinner");
      }
    },
    async handlePending(application) {
      Fire.$emit("PendApplication", application);
    },

    recommend(item) {
      console.log("Item", item);
      this.errors = {}; // Reset errors
      if (!this.approvedProgramme) {
        this.errors.approvedProgramme = "You must select a programme.";
      } else {
        this.modal = true;
        Swal.fire({
          title: "You are about to recommend an applicant for admission",
          text: "Are you sure?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "YES",
          cancelButtonText: "NO",
        }).then((result) => {
          if (result.isConfirmed) {
            let details = {
              applicant: item.applicantId,
              applicationId: item.id,
              programmeId:
                this.approvedProgramme.id == this.programme.id
                  ? this.programme.id
                  : this.approvedProgramme.id,
              admsStatus: "RECOMMENDED",
            };

            this.$store
              .dispatch("AdmissionOfficer/recommend_application", details)
              .then((response) => {
                Fire.$emit("acceptAdmission");
                this.showToast("info", response.data.message);
                this.$store.dispatch("spinner/hideSpinner");
              })
              .catch((err) => {
                Toast.fire({
                  icon: "error",
                  title: err.response.data.message,
                });
              });
          } else {
            Toast.fire({
              icon: "info",
              title: "Operation Cancelled",
            });
            //   throw new axios.Cancel('Operation canceled by the user.');
          }
        });
      }
    },
    async handleUnRecommend() {
      this.$store.dispatch("spinner/showSpinner");
      try {
        let approvedProgramme = await this.getProgrammeForApprove();
        if (approvedProgramme) {
          this.programme = approvedProgramme.programme;
          this.denyModal = true;
        }
        this.$store.dispatch("spinner/hideSpinner");
      } catch (error) {
        console.log(error);
        this.denyBusy = false;
      }
    },
    UnRecommend(item) {
      this.errors = {}; // Reset errors
      if (!this.denyReason) {
        this.errors.denyReason =
          "You must provide reason for rejecting this application.";
      } else {
        Swal.fire({
          title: "You are about to reject this application",
          text: "Are you sure?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "YES",
          cancelButtonText: "NO",
        }).then((result) => {
          if (result.isConfirmed) {
            let details = {
              applicant: item.applicantId,
              applicationId: item.id,
              programmeId: item.programme_id,
              admsStatus: "NRECOMMENDED",
              message: this.denyReason,
            };
            this.$store
              .dispatch("AdmissionOfficer/reject_application", details)
              .then((response) => {
                Fire.$emit("acceptAdmission");
                this.showToast("info", response.data.message);
                this.$store.dispatch("spinner/hideSpinner");
              })
              .catch((err) => {
                Toast.fire({
                  icon: "error",
                  title: err.response.data.message,
                });
              });
          } else {
            Toast.fire({
              icon: "info",
              title: "Operation Cancelled",
            });
            //   throw new axios.Cancel('Operation canceled by the user.');
          }
        });
      }
    },
    showToast(icon, title) {
      Toast.fire({ icon, title });
    },
  },
};
</script>

<style>
.small-font {
  font-size: 0.9rem;
}

.is-invalid {
  border: 1px solid red !important;
}

.custom-dropdown {
  z-index: 1050; /* Adjust z-index as needed */
  overflow-y: scroll;
}
</style>
