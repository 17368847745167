<template>
  <b-container fluid>
    <div class="bg-light">
      <div class="row">
        <div class="col pl-4">
          <b-form-group
            label="Select Application Category"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              v-model="selected.status"
              @change="fetchApplications"
              :options="options"
              :aria-describedby="ariaDescribedby"
              name="radio-inline"
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </div>

      <b-row>
        <b-col md="4" class="my-1">
          <b-form-group class="mb-0">
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Search the table"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- get application given email start -->
        <b-col md="4" class="my-1">
          <b-form-group class="mb-0">
            <b-input-group size="sm">
              <b-form-input
                id="getApplicationGivenEmail"
                v-model="applicantEmail"
                type="text"
                placeholder="Search by email"
              ></b-form-input>

              <b-input-group-append>
                <b-button @click="getApplicationGivenEmail">Search</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- get application given email end -->

        <b-col md="4" class="my-1">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <!-- Main table element -->
    <div style="overflow-x: auto; width: 100%;">
      <b-table
        :busy="isBusy"
        :items="applications_data2"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        stacked="md"
        show-empty
        striped
        responsive
        small
        @filtered="onFiltered"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(name)="row">
          {{ row.item.surname }} {{ row.item.firstname }}
          {{ row.item.lastname }}
        </template>

        <template #cell(email)="row">
          {{ row.item.email }}
        </template>
        <template #cell(mobile)="row">
          {{ row.item.mobile }}
        </template>
        <template #cell(applyFor)="row">
          {{ row.item.applyFor }}
        </template>

        <template #cell(actions)="row">
          <template v-if="row.item.status == 'awaiting submission'">
            Awaiting submission</template
          >
          <template v-else-if="!row.item.status"> No application yet</template>
          <template
            v-else-if="
              row.item.status && row.item.status != 'awaiting submission'
            "
          >
            <template v-if="wantToPrintLetter">
              <button
                class="btn btn-sm btn-outline-primary"
                @click="admissionLetter(row.item.admissionLetter)"
              >
                Admission Letter
              </button>
              <button
                class="btn btn-sm btn-outline-primary"
                @click="reverseAdmission(row.item)"
              >
                Reverse Admission
              </button>
              <admission-letter></admission-letter>
            </template>
            <template v-else>
              <button
                class="btn btn-sm btn-outline-dark"
                @click="viewApplicant(row.item, row.index, $event.target)"
              >
                view
              </button>
              <print-all
                :application_number="row.item.application_number"
                :applicantData="row.item"
                :applicantName="row.item.surname + ' ' + row.item.firstname"
                :mobile="row.item.mobile"
                :email="row.item.email"
                :applicationId="row.item.id"
              />
              <template v-if="$store.state.user.roleInUse != 'pgCoord'">
                <accept-admission :index="row.index" :item="row.item" />
              </template>
              <button
                class="btn btn-outline-dark rounded btn-sm"
                v-b-tooltip.hover
                title="Download All Credentials"
                @click="downloadAll(row.item)"
              >
                <b-icon icon="download" scale="1"></b-icon>
              </button>
            </template>
          </template>
        </template>
        <template #empty="">
          <h4 class="text-danger text-center">No record</h4>
        </template>
        <template #emptyfiltered="">
          <h4 class="text-danger text-center">
            The record you searched for is not avalable
          </h4>
        </template>
      </b-table>
      <!-- Main table element end -->
    </div>
    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
          @change="loadApplications"
        ></b-pagination>
      </b-col>
    </b-row>
    <select-settings-modal />
  </b-container>
</template>

<script>
import { isEmpty } from "lodash";

import AcceptAdmission from "./accept.admission.vue";
import PrintAll from "../Forms/print.all.vue";
import AdmissionLetter from "./admissionLetter.vue";
import SelectSettingsModal from "../../helper/selectSettings.modal.vue";
export default {
  // props: ["applications"],
  components: {
    AcceptAdmission,
    PrintAll,
    AdmissionLetter,
    SelectSettingsModal,
  },
  data() {
    return {
      // applications:
      selected: {
        status: "submitted under processing",
      },
      options: [
        { text: "All ", value: "all" },
        { text: "Submitted ", value: "submitted under processing" },
        { text: "Pending ", value: "awaiting submission" },
        { text: "Denied  ", value: "denied" },
        { text: "Approved ", value: "approved" },
        { text: "Recommended", value: "recommended" },
        { text: "Not Recommended", value: "nrecommened" },
      ],
      isBusy: false,
      fields: [
        {
          key: "name",
          label: "full name",
          sortable: true,
          sortDirection: "desc",
        },
        // {
        //   key: "email",
        //   label: "Email",
        //   sortable: true,
        // },
        {
          key: "mobile",
          label: "Mobile",
          sortable: true,
        },
        {
          key: "programme",
          label: "Programme",
          sortable: true,
        },
        {
          key: "approvedProgramme",
          label: "Approved Programme",
          sortable: true,
        },
        {
          key: "applyFor",
          label: "Applied For",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Date",
          sortable: true,
          formatter: (value, key, item) => {
            return new Date(value).toLocaleString("en-US");
          },
        },
        {
          key: "status",
          label: "Admission Status",
          sortable: true,
        },
        { key: "actions", label: "Actions" },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      wantToPrintLetter: false,
      applications_data2: [],
      applicantEmail: "",
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    applications_data() {
      return this.$store.state.AdmissionOfficer.applications;
    },
  },
  methods: {
    loadSettings() {
      this.$store
        .dispatch("getSettings")
        .then((response) => {
          // this.programmes = response
        })
        .catch((err) => {
          console.log(err);
        });
    },
    admissionLetter(letter) {
      Fire.$emit("SHOW-ADMISSION-LETTER", letter);
    },
    fetchApplications() {
      this.loadApplications();
    },
    printAll() {},
    downloadFile(file) {
      return new Promise((res, rej) => {
        axios({
          url: "admin/downloadFile",
          methods: "GET",
          params: { path: file.path },
          responseType: "blob",
        })
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", file.name);
            document.body.appendChild(fileLink);
            fileLink.click();
            res(true);
          })
          .catch((err) => {
            console.log(err);
            rej(err);
          });
      });
    },
    downloadAll(item) {
      this.$store.dispatch("spinner/showSpinner");
      let credentials = "";
      let assessment = "";
      this.$store
        .dispatch("AdmissionOfficer/getForms", item.id)
        .then((response) => {
          // this.busy.state = false;
          credentials = response.data.credentials;
          assessment = response.data.assessment;
          this.downloadFile(assessment.essay);
          credentials.credentials.forEach(async (element) => {
            await this.downloadFile(element);
          });
          this.$store.dispatch("spinner/hideSpinner");
        })
        .catch((err) => {
          // this.busy.state = false;
          console.log(err);
        });
      console.log(item);
    },
    selectProgramme() {
      console.log("load programme");
      this.$store
        .dispatch("getProgrammes")
        .then((response) => {
          // this.programmes = response
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    viewApplicant(item, index, target) {
      let applicationId = item.id;
      let applicant = item.surname + " " + item.firstname + " " + item.lastname;
      Fire.$emit("VIEW-APPLICANT", {
        applicationId: applicationId,
        applicantName: applicant,
        email: item.email,
        mobile: item.mobile,
        applicantData: item,
        application_number: item.application_number,
      });
    },

    loadApplications() {
      if (isEmpty(this.applications)) {
        this.isBusy = true;
      }
      this.isBusy = true;
      this.$store
        .dispatch("AdmissionOfficer/getApplications", {
          status: this.selected.status,
        })
        .then((response) => {
          this.applications_data2 = response;
          this.totalRows = this.applications_data2.length;
          if (this.selected.status == "approved") {
            this.wantToPrintLetter = true;
          } else {
            this.wantToPrintLetter = false;
          }
          this.isBusy = false;
        })
        .catch((err) => {
          this.isBusy = false;
          Toast.fire({
            icon: "error",
            title: err.error,
          });
        });
    },
    getApplicationGivenEmail() {
      if (isEmpty(this.applications)) {
        this.isBusy = true;
      }
      this.isBusy = true;

      this.$store
        .dispatch("AdmissionOfficer/getApplicationGivenEmail", {
          applicantEmail: this.applicantEmail,
        })
        .then((response) => {
          this.applications_data2 = response;
          this.totalRows = this.applications_data2.length;
          if (this.selected.status == "approved") {
            this.wantToPrintLetter = true;
          } else {
            this.wantToPrintLetter = false;
          }
          this.isBusy = false;
        })
        .catch((err) => {
          this.isBusy = false;
          Toast.fire({
            icon: "error",
            title: err.error,
          });
        });
    },
    reverseAdmission(item) {
      const swalOptions = {
        title:
          "You are about to reset admission approval for this application!",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "YES",
        cancelButtonText: "NO",
      };

      Swal.fire(swalOptions).then((result) => {
        if (result.isConfirmed) {
          const details = { application_id: item.id };
          this.$store
            .dispatch(
              "AdmissionOfficer/reset_application_for_pg_actions",
              details
            )
            .then((res) => {
              Fire.$emit("acceptAdmission");
              this.showToast("info", res.data.message);
              this.$store.dispatch("spinner/hideSpinner");
            })
            .catch((err) => {
              this.showToast("info", "Failed to reset application");
            });
        } else {
          this.showToast("info", "Operation Cancelled");
        }
      });
    },
    showToast(icon, title) {
      Toast.fire({ icon, title });
    },
  },
  mounted() {
    // this.totalRows = this.applications_data2.length;
    this.selectProgramme();
    this.loadSettings();
  },
  created() {
    this.loadApplications();
    Fire.$on("acceptAdmission", () => {
      this.loadApplications();
    });
  },
};
</script>

<style></style>
